import Stars from "../../components/ProductInfo/Stars.component";
import RatingsNumber from "../../components/ProductInfo/RatingsNumber.component";
import styles from "./ProductCard.module.scss";

interface IProductCardReviews {
	ratingAverage: number;
	ratingCount: number;
	wrapperClassName?: string;
}

const ProductCardReviews = ({
	ratingAverage,
	ratingCount,
	wrapperClassName = "",
}: IProductCardReviews) => {
	if (!ratingCount || !ratingAverage) return null;

	return (
		<div
			className={`${styles.reviewsWrapper} ${wrapperClassName}`}
			data-testid="ProductCardReviews_wrapper"
		>
			<Stars ratingAverage={ratingAverage} />
			<span className={styles.cardCount}>
				<RatingsNumber ratingCount={ratingCount} />
			</span>
		</div>
	);
};

export default ProductCardReviews;

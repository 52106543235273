import { EBlockCarouselSlidesPerView, IBlockCarouselCflData } from "./type";
import {
	EMappingIdCflDataType,
	IMappingIdCflData,
} from "@components/ContentfulComponents/ComponentMappingId/type";
import { THomePageCflDataContent } from "@components/ContentfulComponents/ComposeHome/type";
import { isBlockCarousel, isCarouselItem } from "@shared/contentful/contentful.utils";

interface IRetrieveCarouselItemsCodes {
	carousel: IBlockCarouselCflData;
	productMappingIds: IMappingIdCflData[];
	categoryMappingIds: IMappingIdCflData[];
}

export const getSlidesPerView = (data: EBlockCarouselSlidesPerView) => {
	if (!data) return {};
	const splitted = data.split(" / ");
	let breakpoints = {};
	splitted.forEach((s) => {
		const [breakpoint, size] = s.split(": ");
		breakpoints = {
			...breakpoints,
			[breakpoint]: +size,
		};
	});
	return breakpoints;
};

export const filterCarouselBlocks = (content: THomePageCflDataContent[]) => {
	const blockCarousels = content?.filter((collection) => isBlockCarousel(collection));
	if (blockCarousels?.length) return blockCarousels as IBlockCarouselCflData[];
};

const retrieveCarouselItemIds = ({
	carousel,
	productMappingIds,
	categoryMappingIds,
}: IRetrieveCarouselItemsCodes) => {
	carousel?.carouselItemsCollection?.items?.map((item) => {
		if (!item || !isCarouselItem(item) || !item.mappingId?.idType) return;
		if (item.mappingId.idType === EMappingIdCflDataType.Product) {
			productMappingIds.push(item.mappingId);
		} else {
			categoryMappingIds.push(item.mappingId);
		}
	});
};

export const retrieveCarouselBlockIds = (content: THomePageCflDataContent[]) => {
	const carouselBlocks = filterCarouselBlocks(content);
	const productMappingIds: IMappingIdCflData[] = [];
	const categoryMappingIds: IMappingIdCflData[] = [];

	carouselBlocks?.forEach((carousel) => {
		retrieveCarouselItemIds({ carousel, productMappingIds, categoryMappingIds });
	});

	return { productMappingIds, categoryMappingIds };
};

import Link from "next/link";
import { useEffect, useState } from "react";

import { ICarouselItemData } from "../type";
import InnerContent from "./InnerContent.component";

import { ICategory, TCategoryCarousel } from "@components/Category/category.types";
import {
	buildMappingCodesList,
	findCarouselItemInfoById,
} from "@components/ContentfulComponents/ComponentCarouselItem/carousel-items.utils";
import { useAbsolute } from "@hooks/useAbsolute";

import styles from "./inner-content.module.scss";

interface IProps {
	data: ICarouselItemData;
	carouselInfo: TCategoryCarousel[];
}

const IsCategory = ({ data, carouselInfo }: IProps) => {
	const [categoryData, setCategoryData] = useState<ICategory>();

	useEffect(() => {
		if (data?.mappingId) {
			const categoryMatch = findCarouselItemInfoById({
				mappingId: data.mappingId,
				carouselInfo,
				isCategory: true,
			});
			categoryMatch && setCategoryData(categoryMatch as ICategory);
		}
	}, []);

	const { absoluteUrl } = useAbsolute(
		`/c/${buildMappingCodesList(data?.mappingId)?.[0]}/${categoryData?.slug}`
	);

	if (!data || !categoryData?.slug) return null;

	const { asset, itemTitle } = data;

	return (
		<Link href={absoluteUrl} data-testid="IsCategory_wrapper" className={styles.categoryWrapper}>
			<InnerContent itemTitle={itemTitle} asset={asset} />
		</Link>
	);
};

export default IsCategory;

import styles from "./ProductInfo.module.scss";
interface IRatingProps {
	ratingCount: number;
}

const RatingsNumber = ({ ratingCount }: IRatingProps) => {
	return (
		<span className={styles.ratings} data-testid="RatingsNumber_count">
			({ratingCount})
		</span>
	);
};

export default RatingsNumber;

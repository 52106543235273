import React from "react";
import Link from "next/link";

import InnerContent from "./CarouselItemContent/InnerContent.component";
import WithMappingId from "./CarouselItemContent/WithMappingId.component";
import { ICarouselItemData } from "./type";
import { IHomeCarouselInfo } from "../ComposeHome/type";

import styles from "./carousel-item.module.scss";

interface IProps {
	data: ICarouselItemData;
	carouselInfo?: IHomeCarouselInfo;
}

const CarouselItem = ({ data, carouselInfo }: IProps) => {
	if (!data) return null;
	const { mappingId, asset, itemTitle, itemLink } = data;

	if (mappingId) return <WithMappingId data={data} carouselInfo={carouselInfo} />;

	return itemLink?.url ? (
		<Link href={itemLink.url} className={styles.linkWrapper}>
			<InnerContent itemTitle={itemTitle} asset={asset} />
		</Link>
	) : (
		<InnerContent itemTitle={itemTitle} asset={asset} />
	);
};

export default CarouselItem;

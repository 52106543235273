import React, { useContext } from "react";

import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import { filterLabelsByModelId } from "@shared/contentful/contentful.utils";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import CustomImage from "@components/CustomImage/CustomImage.component";
import ProductLabels from "@components/ProductInfo/ProductLabels/ProductLabels.component";
import CarouselProductNameBrand from "@components/Carousels/CarouselProductNameBrand/CarouselProductNameBrand.component";
import CarouselProductPrice from "@components/Carousels/CarouselProductPrice/CarouselProductPrice.component";
import { IProductCarousel } from "@shared/types/product.types";
import ProductCardReviews from "@components/ProductCard/ProductCardReviews.component";
import { ComposeHomeContext } from "@components/ContentfulComponents/ComposeHome/ComposeHomeProvider.component";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { buildCrossSellingProductClickDataLayer } from "@shared/datalayer/cross-selling-product-datalayer/cross-selling-product-datalayer";
import { IAdditionalDataLayerData } from "@components/ContentfulComponents/ComponentCarouselItem/type";

import styles from "./inner-content.module.scss";

export interface ICarouselProductCardProps {
	url: string;
	productData: IProductCarousel;
	dataLayerInfo: IAdditionalDataLayerData;
}

const CarouselProductCard = ({ url, productData, dataLayerInfo }: ICarouselProductCardProps) => {
	const { labels } = useContext(ComposeHomeContext);

	if (!productData || !url) return null;

	const { description, image, priceForFront, brand, review, modelId } = productData;

	if (!image || !Object.keys(image).length) return null;

	const filteredLabels = filterLabelsByModelId({ labels, modelId });

	const mobileProps = getPropsForImages({
		image: { ...image, description: description?.productName },
	});

	const desktopProps = {
		...mobileProps,
		slot: EImageSlot.Third,
	};

	const productClickDataLayer = () => {
		globalDataLayer.customDataLayer.dispatchData(
			buildCrossSellingProductClickDataLayer({
				product: productData,
				...dataLayerInfo,
			})
		);
	};

	return (
		<a
			href={url}
			data-testid="CarouselProductCard_link"
			className={styles.productCardWrapper}
			onClick={productClickDataLayer}
		>
			<CustomImage
				mobile={mobileProps}
				desktop={desktopProps}
				isFromContentful={false}
				hasMixBlend
			/>
			<ProductLabels
				discountPercentage={priceForFront?.discountPercentage}
				labels={filteredLabels}
			/>
			<CarouselProductNameBrand productName={description?.productName} brand={brand} />
			{review && (
				<ProductCardReviews
					ratingAverage={review.ratingAverage}
					ratingCount={review.ratingCount}
					wrapperClassName={styles.productReviewsWrapper}
				/>
			)}
			<CarouselProductPrice priceForFront={priceForFront} />
		</a>
	);
};

export default CarouselProductCard;

import {
	EProductLabelSection,
	IProductLabelCflData,
} from "@components/ContentfulComponents/ComponentProductLabel/type";
import { EProductLabelsIdentifiers } from "./product-labels.query";
import { IProductLabelsProps } from "./ProductLabels.component";

export const sortLabelsByPriority = ({
	labels,
	discountPercentage,
	section,
}: Pick<IProductLabelsProps, "discountPercentage" | "labels" | "section">) => {
	if (labels?.length && section === EProductLabelSection.Price) {
		const sliceUntil = discountPercentage ? 1 : 2;
		const filteredLabels =
			labels.filter((item) => item?.section === EProductLabelSection.Price) || [];
		const sortedLabels = [...filteredLabels].sort(
			(a, b) => (a.labelPriority || 999) - (b.labelPriority || 999)
		);
		return sortedLabels.slice(0, sliceUntil);
	} else {
		return labels ?? [];
	}
};

export const isLabelPriceDrop = (label: IProductLabelCflData) => {
	return label?.identifier === EProductLabelsIdentifiers.PriceDrop;
};

import React, { memo } from "react";
import { SwiperSlide } from "swiper/react";

import CarouselItem from "../ComponentCarouselItem/CarouselItem.component";
import Asset from "../ComponentAsset/Asset.component";
import CarouselLayout from "@components/Carousels/CarouselLayout/CarouselLayout.component";
import { EGridSize } from "../ContentPagesContentBlock/type";
import { IBlockCarouselCflData } from "./type";
import { IHomeCarouselInfo } from "../ComposeHome/type";
import { getSlidesPerView } from "./utils";

import { isAsset, isCarouselItem } from "@shared/contentful/contentful.utils";

import styles from "./block-carousel.module.scss";

interface IProps {
	data: IBlockCarouselCflData;
	gridSize: EGridSize;
	carouselInfo?: IHomeCarouselInfo;
}

const BlockCarousel = ({ data, gridSize, carouselInfo }: IProps) => {
	if (!data?.carouselItemsCollection?.items?.length) return null;

	const {
		carouselItemsCollection,
		title,
		displayArrows,
		sys: { id },
	} = data;

	const prevArrow = `prev-${id}`;
	const nextArrow = `next-${id}`;

	return (
		<CarouselLayout
			gridSize={gridSize}
			nextArrow={nextArrow}
			prevArrow={prevArrow}
			slidesPerView={getSlidesPerView(data?.slidesPerView)}
			displayArrows={displayArrows}
			title={title}
		>
			{carouselItemsCollection.items.map((item, index) => {
				if (!item) return null;
				const isCarousel = isCarouselItem(item);

				if (!isCarousel && !isAsset(item)) return null;
				return (
					<SwiperSlide key={item.sys?.id} className={styles.singleSlide}>
						{isCarousel ? (
							<CarouselItem
								key={item.sys?.id}
								carouselInfo={carouselInfo}
								data={{ ...item, index, list: title }}
							/>
						) : (
							<Asset data={item} className={styles.assetSlide} loading />
						)}
					</SwiperSlide>
				);
			})}
		</CarouselLayout>
	);
};

export default memo(BlockCarousel);

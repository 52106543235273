import React, { memo } from "react";
import dynamic from "next/dynamic";

import {
	EProductLabelSection,
	IProductLabelCflData,
} from "@components/ContentfulComponents/ComponentProductLabel/type";
import { sortLabelsByPriority, isLabelPriceDrop } from "./product-labels.utils";

import styles from "./ProductLabels.module.scss";

const DynamicDiscountLabel = dynamic(() => import("./DiscountLabel.component"));

const DynamicProductLabel = dynamic(
	() => import("@components/ContentfulComponents/ComponentProductLabel/ProductLabel.component")
);

export interface IProductLabelsProps {
	labels?: IProductLabelCflData[];
	wrapperClassName?: string;
	discountPercentage?: number;
	section?: EProductLabelSection;
	isProductPage?: boolean;
}

const ProductLabels = ({
	discountPercentage = 0,
	wrapperClassName = "",
	section = EProductLabelSection.Price,
	isProductPage = false,
	labels,
}: IProductLabelsProps) => {
	const productLabels = sortLabelsByPriority({ labels, discountPercentage, section });

	if (
		(!productLabels?.length && discountPercentage === 0) ||
		(isProductPage && productLabels?.length === 1 && isLabelPriceDrop(productLabels[0]))
	) {
		return null;
	}

	return (
		<div
			className={`${styles.wrapper} ${wrapperClassName} ${
				isProductPage ? styles.isProductPage : ""
			}`}
			data-testid="ProductLabels_wrapper"
		>
			{discountPercentage !== 0 && <DynamicDiscountLabel discountPercentage={discountPercentage} />}
			{productLabels?.map((label) => {
				if (
					(label?.section && section !== label.section) ||
					(isProductPage && isLabelPriceDrop(label))
				) {
					return null;
				}
				return <DynamicProductLabel key={label.label} data={label} isProductPage={isProductPage} />;
			})}
		</div>
	);
};

export default memo(ProductLabels);

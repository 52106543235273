import { useState, useEffect } from "react";
import { ICarouselItemData } from "../type";
import CarouselProductCard from "./CarouselProductCard.component";
import { findCarouselItemInfoById } from "@components/ContentfulComponents/ComponentCarouselItem/carousel-items.utils";
import { useAbsolute } from "@hooks/useAbsolute";
import { IProductCarousel } from "@shared/types/product.types";
import { formatTitleForDataLayerList } from "@components/ContentfulComponents/ComposeHome/HomeContent/HomeContent.utils";

interface IProps {
	data: ICarouselItemData;
	carouselInfo: IProductCarousel[];
}

const IsProduct = ({ data, carouselInfo }: IProps) => {
	const [productData, setProductData] = useState<IProductCarousel>();

	useEffect(() => {
		if (data?.mappingId) {
			const productMatch = findCarouselItemInfoById({ mappingId: data.mappingId, carouselInfo });
			productMatch && setProductData(productMatch as IProductCarousel);
		}
	}, []);

	const { absoluteUrl } = useAbsolute(
		`/p/${productData?.modelId}/${productData?.description?.slug}`
	);

	if (!data || !productData?.modelId || !productData.description?.slug) return null;

	return (
		<CarouselProductCard
			url={absoluteUrl}
			productData={productData}
			dataLayerInfo={{
				list: formatTitleForDataLayerList(data.list),
				index: data.index,
			}}
		/>
	);
};

export default IsProduct;

import { EMappingIdCflDataType } from "@components/ContentfulComponents/ComponentMappingId/type";
import { IHomeCarouselInfo } from "@components/ContentfulComponents/ComposeHome/type";
import { ICarouselItemData } from "../type";

import IsCategory from "./IsCategory.component";
import IsProduct from "./IsProduct.component";

interface IProps {
	data: ICarouselItemData;
	carouselInfo?: IHomeCarouselInfo;
}

const WithMappingId = ({ data, carouselInfo }: IProps) => {
	if (!carouselInfo) return null;

	switch (data?.mappingId?.idType) {
		case EMappingIdCflDataType.Product:
			return <IsProduct data={data} carouselInfo={carouselInfo.productCarouselInfo} />;

		case EMappingIdCflDataType.Category:
			return <IsCategory data={data} carouselInfo={carouselInfo.categoryCarouselInfo} />;

		default:
			return null;
	}
};

export default WithMappingId;

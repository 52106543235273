import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import { ICarouselItemCflData } from "../type";

import styles from "./inner-content.module.scss";

const InnerContent = ({ asset, itemTitle }: Pick<ICarouselItemCflData, "asset" | "itemTitle">) => {
	if (!asset && !itemTitle) return null;
	return (
		<div className={styles.wrapper} data-testid="InnerContent_wrapper">
			{asset && (
				<Asset
					data={asset}
					imageSlot={{ mobile: EImageSlot.Full, desktop: EImageSlot.Third }}
					loading
				/>
			)}
			{itemTitle && (
				<p className={styles.title} data-testid="InnerContent_title">
					{itemTitle}
				</p>
			)}
		</div>
	);
};

export default InnerContent;

import { colors } from "@shared/styles/theme";
import styles from "./ProductInfo.module.scss";
import Icon, { EIcon } from "../Icon/Icon.component";
import { IAggregateRatingReview } from "@shared/types/product.types";

const { greyLight2, black } = colors;
export type TStars = Pick<IAggregateRatingReview, "ratingAverage"> & { color?: string };

const Stars = ({ ratingAverage, color }: TStars) => {
	const stars = ratingAverage;
	const starsColor = color || black;

	const allStars = [...Array(5).keys()].map((item) => {
		if (item < stars && item + 1 > stars) {
			return (
				<div key={item} className={`${styles.sizeStar} ${styles.starContainer}`}>
					<div className={styles.leftStar}>
						<svg
							data-testid="Icon_half-left"
							xmlns="https://www.w3.org/2000/svg"
							width="1em"
							height="1em"
							viewBox="0 0 24 24"
							fill={greyLight2}
							stroke={greyLight2}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-star"
						>
							<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
						</svg>
					</div>
					<div className={styles.rightStar}>
						<svg
							data-testid="Icon_half-right"
							xmlns="https://www.w3.org/2000/svg"
							width="1em"
							height="1em"
							viewBox="0 0 24 24"
							fill={starsColor}
							stroke={starsColor}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-star"
						>
							<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
						</svg>
					</div>
				</div>
			);
		}

		if (item < stars) {
			return (
				<div key={item} className={styles.sizeStar}>
					<Icon
						icon={EIcon.IconStar}
						color={starsColor}
						fill={starsColor}
						size="1em"
						noActive
						noHover
						data-testid="Icon_filled"
					/>
				</div>
			);
		}

		return (
			<div key={item} className={styles.sizeStar}>
				<Icon
					icon={EIcon.IconStar}
					color={greyLight2}
					fill={greyLight2}
					size="1em"
					noActive
					noHover
					data-testid="Icon_empty"
				/>
			</div>
		);
	});

	return (
		<div className="flex items-center" data-testid="Stars_wrapper">
			{allStars}
		</div>
	);
};

export default Stars;
